/**
 *
 * SignupModal
 *
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RegistrationModal from './index';

const SignupModal = ({ modal }) => (
  <RegistrationModal type="signup" modalPathname="/register" modal={modal} />
);

SignupModal.propTypes = {
  modal: PropTypes.object,
};

const mapStateToProps = ({ display, modals }) => ({
  display,
  modal: modals.registrationModal,
});

export default connect(mapStateToProps)(SignupModal);
